import { VStack, Heading, Box, Text, Link } from "@chakra-ui/react";
import bokehJpg from "../../images/bokeh.jpg";
const FAQ = () => {
    return (
        <Box
            minHeight={"calc(100dvh - 64px)"}
            backgroundImage={bokehJpg}
            backgroundSize="cover"
        >
            <VStack
                backgroundBlendMode={"multiply"}
                backgroundColor={"rgba(50,50,50,0.5)"}
                spacing={8}
                align="start"
                px={{
                    base: "1rem",

                    md: "2rem",
                    lg: "3rem",
                    xl: "4rem",
                }}
                textAlign={{
                    base: "center",
                    md: "left",
                }}
                py={{
                    base: "1rem",
                    md: "2rem",
                }}
                maxWidth="1080px"
                m={"auto"}
            >
                <Heading as="h1">FAQ - Refund & No-Show Policy</Heading>

                <Box>
                    <Heading as="h2" size="lg">
                        Refund & No-Show Policy
                    </Heading>
                    <Text>
                        Once your lesson or package has been purchased we do not
                        allow refunds.
                    </Text>
                    <Text>
                        If you are unable to make your scheduled date you will
                        have two opportunities to reschedule the lesson in
                        question. If after rescheduling twice you are still
                        unable to attend or do not show to your appointment you
                        will forfeit the lesson, so please choose your lesson
                        dates carefully.
                    </Text>
                    <Text>
                        If you need to reschedule your lesson please notify our
                        team 24 hours prior to your scheduled date at{" "}
                        <Link href="mailto:firstdancevirginia@gmail.com">
                            firstdancevirginia@gmail.com
                        </Link>{" "}
                        with your new requested date and time.
                    </Text>
                </Box>

                <Box>
                    <Heading as="h2" size="lg">
                        Mother & Son / Father & Daughter Dance
                    </Heading>
                    <Text>
                        FDVA is not only the ultimate resource for engaged
                        couples, but also for mother/son and father/daughter
                        pairs looking to stand out and make an impact with their
                        dance.
                    </Text>
                    <Text>
                        The process for booking a parent child dance lesson is
                        the same for our engaged couples. Simply decide on a
                        choreographed piece or our partner dance 101 lessons and
                        book through our website with a note such as “mother/son
                        dance”.
                    </Text>
                </Box>

                <Box>
                    <Heading as="h2" size="lg">
                        Wedding Party Dances
                    </Heading>
                    <Text>
                        Choreographed bridal party dances have become one of the
                        most popular wedding trends of our time due to their
                        upbeat, and often times comedic, nature that excite the
                        crowd and set the tone for the rest of the night. At
                        FDVA we are thrilled to work with your wedding party to
                        create an unforgettable team dance.
                    </Text>
                    <Text>
                        Due to the unique variables involved in bridal party
                        choreography, please contact us at{" "}
                        <Link href="mailto:firstdancevirginia@gmail.com">
                            firstdancevirginia@gmail.com
                        </Link>
                        to determine pricing and scheduling.
                    </Text>
                </Box>

                <Box>
                    <Heading as="h2" size="lg">
                        What Should I Wear?
                    </Heading>
                    <Text>
                        Comfort is always important when learning any new
                        physical skill. However, equally as important for an
                        engaged couple is practicing your dance in the shoes and
                        clothes you intend to wear on your wedding day.
                    </Text>
                    <Text>
                        We recommend starting your lesson in something you can
                        easily move around in and bringing a pair of heels
                        and/or dress shoes that you can change into to adapt to
                        the feeling of dancing in them.
                    </Text>
                    <Text>
                        Brides who are planning on wearing a long gown at their
                        first dance may want to bring a long skirt to practice
                        in as well.
                    </Text>
                </Box>
                <Box>
                    <Heading as="h2" size="lg">
                        Can I Learn My First Dance at Home?
                    </Heading>
                    <Text>
                        Yes! FDVA gives you the option to take your lessons out
                        of one of our many studio locations or request our
                        instructors to bring the lessons to you.
                    </Text>
                    <Text>
                        Many couples appreciate the benefits of learning their
                        first dance at home such as the comfort of learning in a
                        familiar environment, lack of stress over making it to
                        an appointment outside on time, and easy access to
                        resources such as additional practice shoes and clothes,
                        cameras, notepads, etc.
                    </Text>
                    <Text>
                        The benefits of taking lessons in a studio include large
                        open spaces to move around in, floor length mirrors to
                        closely examine form and progress, and the opportunity
                        to go out and spend time together away from home.
                    </Text>
                </Box>
            </VStack>
        </Box>
    );
};
export default FAQ;
