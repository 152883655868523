import { Box } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "../layout/Footer";
import Header from "../layout/Header";

import { publicRoutes } from "./Routes";

// import RequireAuth from "../auth/RequireAuth";
// import Breadcrumbs from "../components/Breadcrumbs";
// import Header from "../components/Header";
// import ErrorPage from "../pages/ErrorPage";

function Routings() {
    const location = useLocation();

    const variants = {
        initial: { opacity: 0 },
        in: { opacity: 1, x: 0 },
        out: { opacity: 0 },
    };

    const transition = {
        type: "tween",
        ease: "anticipate",
        duration: 0.4,
    };

    return (
        <Box position="relative" zIndex={0}>
            <AnimatePresence exitBeforeEnter initial={false}>
                <Box height="64px">
                    <Header />
                </Box>
            </AnimatePresence>
            <AnimatePresence exitBeforeEnter initial={false}>
                <Routes key={location.pathname} location={location}>
                    {publicRoutes.map(({ element, ...publicRouteProps }) => (
                        <Route
                            {...publicRouteProps}
                            element={
                                <>
                                    <motion.div
                                        initial="initial"
                                        animate="in"
                                        exit="out"
                                        variants={variants}
                                        transition={transition}
                                        key={publicRouteProps.path as string}
                                    >
                                        {element}
                                    </motion.div>
                                </>
                            }
                            key={publicRouteProps.path as string}
                        />
                    ))}
                    {/*  <Route path="*" element={<ErrorPage />} /> */}
                </Routes>
            </AnimatePresence>
            <Footer />
        </Box>
    );
}

export default Routings;
