import { Button } from "@chakra-ui/react";

import { MdArrowForwardIos } from "react-icons/md";

interface NextButtonProps {
    handleClick: () => void;
    isDisabled?: boolean;
}

const NextButton = ({ handleClick, isDisabled = false }: NextButtonProps) => {
    return (
        <Button
            size="md"
            onClick={handleClick}
            outline={"white solid"}
            variant="ghost"
            isDisabled={isDisabled}
            mt={4}
            _hover={{
                backgroundColor: "rgba(0,0,0,0.1)",
            }}
            rightIcon={<MdArrowForwardIos />}
        >
            Next
        </Button>
    );
};

export default NextButton;
