import {
    Container,
    Stack,
    ButtonGroup,
    IconButton,
    Text,
} from "@chakra-ui/react";

import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { Logo } from "../Logo";

const Footer = () => {
    return (
        <Container
            as="footer"
            zIndex={5}
            backgroundColor={"gray.800"}
            py={{ base: "12", md: "16" }}
        >
            <Stack spacing={{ base: "4", md: "5" }}>
                <Stack justify="space-between" direction="row" align="center">
                    <Logo inverse />
                    <ButtonGroup variant="ghost">
                        <IconButton
                            as="a"
                            href="https://www.linkedin.com/company/first-dance-virginia-llc/"
                            aria-label="LinkedIn"
                            icon={
                                <FaLinkedin fontSize="1.25rem" color="white" />
                            }
                        />

                        <IconButton
                            as="a"
                            href="https://www.instagram.com/firstdancevirginia/"
                            aria-label="Instagram"
                            icon={
                                <FaInstagram fontSize="1.25rem" color="white" />
                            }
                        />
                        {/* Email button */}
                        <IconButton
                            as="a"
                            href="mailto:firstdancevirginia@gmail.com"
                            aria-label="Email"
                            icon={<FiMail fontSize="1.25rem" color="white" />}
                        />
                    </ButtonGroup>
                </Stack>
                <Text fontSize="sm" color="subtle">
                    &copy; {new Date().getFullYear()} First Dance Virginia LLC.
                </Text>
            </Stack>
        </Container>
    );
};

export default Footer;
