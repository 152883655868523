import {
    Box,
    Flex,
    HStack,
    Icon,
    IconButton,
    useBreakpointValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { RefObject } from "react";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import SETTINGS from "../../data/ContentInfo";
import BookAClassButton from "../buttons/BookAClassButton";
import ElegantNav from "./ElegantNav";
import { Logo } from "../Logo";

type NavLayoutProps = {
    onClickMenu?: VoidFunction;
    onToggleMode?: VoidFunction;
    isMenuOpen: boolean;
    menuButtonRef?: RefObject<HTMLButtonElement>;
};

export const NavLayout = (props: NavLayoutProps) => {
    const isDesktopMode = useBreakpointValue({ xl: true, base: false });
    const { onClickMenu, isMenuOpen, menuButtonRef } = props;
    const MenuIcon = isMenuOpen ? MdClose : FiMenu;
    return (
        <>
            <Flex
                as={motion.div}
                height="16"
                align="center"
                justify="space-between"
                px="5"
                backgroundColor="transparent"
                zIndex={10}
                initial={{ opacity: 0 }}
                animate={{
                    opacity: 1,
                    transition: {
                        duration: 0.5,
                        delay: 0.5,
                    },
                }}
                exit={{ opacity: 0 }}
            >
                <Logo inverse={isMenuOpen && !isDesktopMode} />

                {isDesktopMode ? (
                    <ElegantNav />
                ) : (
                    <HStack>
                        <IconButton
                            ref={menuButtonRef}
                            variant="ghost"
                            boxShadow="sm"
                            //bg color dark but opacity 0.5
                            backgroundColor="rgba(0, 0, 0, 0.3)"
                            icon={
                                <Icon
                                    as={MenuIcon}
                                    fontSize="2xl"
                                    color="white"
                                />
                            }
                            aria-label="Open Menu"
                            onClick={onClickMenu}
                            _hover={{
                                backgroundColor: "gray.300",
                                opacity: 0.5,
                            }}
                        />
                        {!isMenuOpen && <BookAClassButton />}
                    </HStack>
                )}
            </Flex>
            {SETTINGS.HasBanner && (
                <Box
                    as={motion.div}
                    fontWeight={200}
                    fontSize={{
                        base: "xs",
                        md: "md",
                    }}
                    color="white"
                    textAlign="center"
                    bg="rgba(105, 15, 5, 0.2)"
                    boxShadow={"md"}
                    position="absolute"
                    top="64px"
                    width={"100%"}
                    zIndex={400}
                    whiteSpace="nowrap"
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.5,
                            delay: 0.5,
                        },
                    }}
                    exit={{ opacity: 0 }}
                >
                    {SETTINGS.Banner}
                </Box>
            )}
        </>
    );
};
