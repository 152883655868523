import { Heading, Box, Flex, Text, IconButton } from "@chakra-ui/react";
import "@natscale/react-calendar/dist/main.css";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { useState, useCallback, useEffect } from "react";
import { Logo } from "../Logo";
import StepOne, { TimeSlot } from "../booking/StepOne";
import {
    ClassOptionType,
    PackageOptions,
    PackageOptionType,
    StepTwo,
} from "../booking/StepTwo";
import "react-phone-input-2/lib/style.css";
import { useForm } from "react-hook-form";
import BImage from "../../images/rose.jpg";
import StepThree from "../booking/StepThree";
import { supabase } from "../../supabaseClient";
import { AdminIntroEmail, welcomeEmail } from "../emails";
import BookingConfirmation from "../booking/BookingConfirmation";
import { EmailIcon } from "@chakra-ui/icons";

const Booking = () => {
    const { register, handleSubmit } = useForm<any>();
    const [date, setDate] = useState<Date>();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDateSlots, setSelectedDateSlots] = useState<
        TimeSlot[] | undefined
    >();
    const [selectedSlot, setSelectedSlot] = useState<TimeSlot | undefined>();
    const [selectedClass, setSelectedClass] = useState<ClassOptionType>();
    const [selectedPackage, setSelectedPackage] = useState<PackageOptionType>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [currentStep, setCurrentStep] = useState(0);

    const onSubmit = handleSubmit(async (formData: any) => {
        setIsLoading(true);
        // console.log({
        //     date: date?.toDateString(),
        //     selectedSlot,
        //     selectedClass: selectedClass?.value,
        //     formData,
        //     phoneNumber,
        // });

        // Process  after Data is received on the BE
        // 1. Send email to user with booking details & payment link
        // 2. Send email to admin with booking details
        // 3. Redirect user to confirmation page which asks them to check their email

        const StripePaymentLink = (selectedPackage: PackageOptions) => {
            if (selectedPackage === PackageOptions.One) {
                return "https://buy.stripe.com/test_7sIeYQ0aK8qX56o5kn";
            } else if (selectedPackage === PackageOptions.Three) {
                return "https://buy.stripe.com/test_bIY4kc2iS7mTdCUbIM";
            } else if (selectedPackage === PackageOptions.Five) {
                return "https://buy.stripe.com/test_5kAaIA2iSfTpaqIcMR";
            } else if (selectedPackage === PackageOptions.Ten) {
                return "https://buy.stripe.com/test_4gw03WaPofTp6asdQW";
            } else {
                return "";
            }
        };

        const PaymentMessage = (selectedPackage: PackageOptions) => {
            if (selectedPackage === PackageOptions.One) {
                return "$100";
            } else if (selectedPackage === PackageOptions.Three) {
                return "$285";
            } else if (selectedPackage === PackageOptions.Five) {
                return "$372";
            } else if (selectedPackage === PackageOptions.Ten) {
                return "$810";
            } else {
                return "";
            }
        };

        const ToClientIntroEmail = {
            sender: "booking@fdva.com",
            recipient: formData.email,
            subject: `Confirm your ${selectedClass?.label} booking! 💃 `,
            html_body: welcomeEmail(
                formData.name,
                (selectedPackage && PaymentMessage(selectedPackage.value)) ||
                    "",
                (selectedPackage && StripePaymentLink(selectedPackage.value)) ||
                    ""
            ),
        };

        const { error } = await supabase.rpc("send_email", {
            message: ToClientIntroEmail,
        });

        if (error) {
            alert("Email server error. Please try again later or contact us.");
        } else {
            const ToAdminIntroEmail = {
                sender: "admin@fdva.com",
                recipient: "weschen1996@gmail.com",
                subject: `New ${selectedClass?.label} booking! 💃 ${formData.name} `,
                html_body: AdminIntroEmail(formData.name, {
                    date: date?.toDateString() || "",
                    ...selectedSlot,
                    selectedPackage: selectedPackage?.value || "",
                    selectedClass: selectedClass?.value || "",
                    ...formData,
                    phoneNumber: phoneNumber || "",
                }),
            };
            const { error } = await supabase.rpc("send_email", {
                message: ToAdminIntroEmail,
            });
            if (error) {
                alert(
                    "Email server error. Please try again later or contact us."
                );
            } else {
                setCurrentStep(10);
                setIsLoading(false);
            }
        }
    });

    const onChange = useCallback(
        (val: any) => {
            setDate(val);
        },
        [setDate]
    );

    useEffect(() => {
        setIsLoading(true);
        setSelectedSlot(undefined);

        if (!date) return;

        const startOfDay = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        ).toISOString();
        const endOfDay = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() + 1
        ).toISOString();

        const url = `https://www.googleapis.com/calendar/v3/calendars/${process.env.REACT_APP_CALENDAR_ID}/events?`;

        const searchParams = new URLSearchParams({
            key: process.env.REACT_APP_CALENDAR_API_KEY || "",
            showDeleted: "false",
            timeMin: startOfDay,
            timeMax: endOfDay,
        });

        const generateTimeSlots = async () =>
            await fetch(url + searchParams, {
                method: "GET",
            }).then((res) => res.json());

        generateTimeSlots().then((data) => {
            // console.log(data);
            const allTimeSlots = data.items
                .filter((timeSlot: any) => timeSlot.status !== "cancelled")
                .map((timeSlot: any, index: number) => {
                    // console.log(timeSlot);
                    return {
                        time: new Date(
                            timeSlot.start.dateTime
                        ).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                        }),
                        id: timeSlot.id,
                    };
                });

            const canceledTimeSlots = data.items.filter(
                (timeSlot: any) => timeSlot.status === "cancelled"
            );

            // console.log(canceledTimeSlots);
            // canceledTimeSlots have an id whose first 10 digits  matches the id of the time slot in allTimeSlots
            // we need to remove the canceled time slots from allTimeSlots

            const updatedTimeSlots = allTimeSlots.filter((timeSlot: any) => {
                const canceledTimeSlot = canceledTimeSlots.find(
                    (canceledTimeSlot: any) => {
                        return (
                            canceledTimeSlot.id.slice(0, 10) ===
                            timeSlot.id.slice(0, 10)
                        );
                    }
                );
                return !canceledTimeSlot;
            });
            // console.log(updatedTimeSlots);
            setSelectedDateSlots([...updatedTimeSlots]);
            setIsLoading(false);
        });
    }, [date]);

    const handleSlotSelect = (slot: TimeSlot) => {
        setSelectedSlot(slot);
    };

    const handleClassTypeSelect = (classType: ClassOptionType) => {
        setSelectedClass(classType);
    };

    const handlePackageTypeSelect = (packageType: PackageOptionType) => {
        setSelectedPackage(packageType);
    };

    const handleBackStep = () => {
        if (currentStep === 0) return;
        setCurrentStep(currentStep - 1);
    };

    const handleForwardStep = () => {
        if (currentStep === 2) return;
        setCurrentStep(currentStep + 1);
    };

    // useEffect(() => {
    //     console.log(selectedDateSlots);
    // }, [selectedDateSlots]);

    return (
        <Box
            mt="-64px"
            minHeight="100dvh"
            height="100%"
            p={{
                base: "1rem",
                md: "2rem",
            }}
            display={"flex"}
            flexDirection={"column"}
            backgroundImage={BImage}
            backgroundSize="cover"
        >
            <NoNavHeader
                header={currentStep > 5 ? "Confirmation" : "Booking"}
            />
            <AnimateSharedLayout>
                <Box
                    as={motion.div}
                    p={{
                        base: "1rem",
                        md: "2rem",
                    }}
                    mx={"auto"}
                    boxShadow="base"
                    rounded="md"
                    layout
                    backgroundColor={"rgba(0,0,0,0.2)"}
                >
                    <AnimatePresence initial={false} exitBeforeEnter>
                        {currentStep === 0 && (
                            <StepOne
                                date={date}
                                handleForwardStep={handleForwardStep}
                                handleSlotSelect={handleSlotSelect}
                                isLoading={isLoading}
                                key={`
                                    step-1
                                `}
                                onChange={onChange}
                                selectedDateSlots={selectedDateSlots}
                                selectedSlot={selectedSlot}
                            />
                        )}
                        {currentStep === 1 && (
                            <StepTwo
                                date={date}
                                handleBackStep={handleBackStep}
                                handleClassTypeSelect={handleClassTypeSelect}
                                handleForwardStep={handleForwardStep}
                                handlePackageTypeSelect={
                                    handlePackageTypeSelect
                                }
                                key="step-1"
                                selectedClass={selectedClass}
                                selectedSlot={selectedSlot}
                                selectedPackage={selectedPackage}
                            />
                        )}
                        {currentStep === 2 && (
                            <StepThree
                                handleBackStep={handleBackStep}
                                key="step-2"
                                selectedClass={selectedClass}
                                register={register}
                                onSubmit={onSubmit}
                                phoneNumber={phoneNumber}
                                setPhoneNumber={setPhoneNumber}
                                isLoading={isLoading}
                            />
                        )}
                        {currentStep === 10 && (
                            <>
                                <BookingConfirmation key="step-10" />
                            </>
                        )}
                    </AnimatePresence>
                </Box>
            </AnimateSharedLayout>
            {currentStep === 10 && (
                <Box mt={4} textAlign={"center"}>
                    <Text textShadow={"0 0 12px rgba(0,0,0,1)"}>
                        Email us at firstdancevirginia@gmail.com
                        <IconButton
                            as="a"
                            href="mailto:firstdancevirginia@gmail.com"
                            icon={<EmailIcon />}
                            variant={"ghost"}
                            aria-label={""}
                            size={"md"}
                            ml={2}
                            rounded={"full"}
                        ></IconButton>
                    </Text>
                </Box>
            )}
        </Box>
    );
};

export default Booking;

type NoNavHeaderProps = {
    header: string;
};
const NoNavHeader = ({ header }: NoNavHeaderProps) => {
    return (
        <Flex
            flexDir={{
                base: "column",
                md: "row-reverse",
            }}
            textAlign="center"
            justifyContent="space-evenly"
            alignItems={"center"}
        >
            <Logo stayBig />
            <Heading
                mb={4}
                textAlign={"center"}
                textShadow={"0 0 12px rgba(0,0,0,0.4)"}
            >
                {header}
            </Heading>
        </Flex>
    );
};
