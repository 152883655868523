import { VStack, Box, Heading, Text, Container } from "@chakra-ui/react";
import { motion } from "framer-motion";
import BackButton from "../buttons/BackButton";
import NextButton from "../buttons/NextButton";
import { RadioCardGroup, RadioCard } from "../layout/RadioCardGroup";
import { TimeSlot } from "./StepOne";

export type ClassOptionType = {
    label: string;
    description: string;
    value: ClassOptions;
};

export type PackageOptionType = {
    label: string;
    description: string;
    value: PackageOptions;
};

export enum ClassOptions {
    Choreographed = "choreographed",
    Partner = "partner-dance-101",
}

export enum PackageOptions {
    One = "one",
    Three = "three",
    Five = "five",
    Ten = "ten",
}

export const classOptions: ClassOptionType[] = [
    {
        label: "Choreographed First Dance",
        description:
            "Spend some quality time with your partner while learning a beautiful first dance routine that reflects your unique style. Our experienced choreographers will help you feel confident and relaxed, so you can take a break from the stress of wedding planning.",
        value: ClassOptions.Choreographed,
    },
    {
        label: "Partner Dance 101",
        description:
            "Take a break from the wedding planning chaos and join us for a stress-free partner dance class. From salsa to swing, you'll learn new moves and connect with your partner in a fun and relaxing environment, leaving you feeling rejuvenated and ready to tackle anything.",
        value: ClassOptions.Partner,
    },
];

export const packageOptions: PackageOptionType[] = [
    {
        label: "1 Lesson Package",
        description: "$100",
        value: PackageOptions.One,
    },
    {
        label: "3 Lesson Package",
        description: "$285",
        value: PackageOptions.Three,
    },
    {
        label: "5 Lesson Package",
        description: "$372 - April Discount",
        value: PackageOptions.Five,
    },
    {
        label: "10 Lesson Package",
        description: "$810 - April Discount",
        value: PackageOptions.Ten,
    },
];

interface StepTwoProps {
    date: Date | undefined;
    handleBackStep: () => void;
    handleClassTypeSelect: (type: ClassOptionType) => void;
    handleForwardStep: () => void;
    handlePackageTypeSelect: (type: PackageOptionType) => void;
    selectedClass: ClassOptionType | undefined;
    selectedSlot: TimeSlot | undefined;
    selectedPackage: PackageOptionType | undefined;
}
export const StepTwo = ({
    date,
    handleBackStep,
    handleClassTypeSelect,
    handleForwardStep,
    handlePackageTypeSelect,
    selectedClass,
    selectedSlot,
    selectedPackage,
}: StepTwoProps) => {
    return (
        <Box
            as={motion.div}
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            key="step-1"
        >
            <BackButton handleClick={handleBackStep} />

            <VStack height={"100%"}>
                <Heading
                    width={"100%"}
                    size={{
                        base: "md",
                        md: "lg",
                    }}
                    textAlign={{
                        base: "center",
                        md: "left",
                    }}
                >
                    Step 2. Choose your Package
                </Heading>

                <Text
                    as={motion.div}
                    key={date?.toDateString()}
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    fontSize={{
                        base: "sm",
                        md: "lg",
                    }}
                >
                    {date
                        ? date.toDateString() +
                          (selectedSlot ? ` ${selectedSlot?.time}` : "")
                        : "Please select a dates"}
                </Text>
                <Box
                    display={"flex"}
                    flexDir={{
                        base: "column",
                        md: "row",
                    }}
                >
                    <Container flex={3}>
                        <RadioCardGroup
                            spacing="2"
                            value={selectedClass?.value}
                        >
                            {classOptions.map((option) => (
                                <RadioCard
                                    key={option.value}
                                    value={option.value}
                                    onClick={() =>
                                        handleClassTypeSelect(option)
                                    }
                                >
                                    <Text
                                        color="emphasized"
                                        fontWeight="medium"
                                        fontSize="sm"
                                    >
                                        {option.label}
                                    </Text>
                                    <Text color="muted" fontSize="sm">
                                        {option.description}
                                    </Text>
                                </RadioCard>
                            ))}
                        </RadioCardGroup>
                    </Container>
                    <Container
                        flex={2}
                        mt={{
                            base: 2,
                            md: 0,
                        }}
                    >
                        <RadioCardGroup
                            spacing="2"
                            value={selectedPackage?.value}
                            width={"100%"}
                        >
                            {packageOptions.map((option) => (
                                <RadioCard
                                    key={option.value}
                                    value={option.value}
                                    onClick={() =>
                                        handlePackageTypeSelect(option)
                                    }
                                >
                                    <Text
                                        color="emphasized"
                                        fontWeight="medium"
                                        fontSize="sm"
                                    >
                                        {option.label}
                                    </Text>
                                    <Text color="muted" fontSize="sm">
                                        {option.description}
                                    </Text>
                                </RadioCard>
                            ))}
                        </RadioCardGroup>
                    </Container>
                </Box>
            </VStack>

            <Box
                mt={{
                    base: 2,
                    md: 4,
                }}
                textAlign={"center"}
            >
                <NextButton
                    handleClick={handleForwardStep}
                    isDisabled={!selectedClass || !selectedPackage}
                />
            </Box>
        </Box>
    );
};
