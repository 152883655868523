import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
  description?: string;
}

export interface CustomVideo extends CustomImage {
  type: "video";
  poster: string;
  sources: {
    src: string;
    type: string;
  }[];
  description?: string;
}

export const videos = [
  {
    type: "video",
    width: 1280,
    height: 720,
    poster:
      "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg",
    sources: [
      {
        src: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        type: "video/mp4",
      },
    ],
    tags: [{ value: "Video", title: "Video" }],
    description: "Big Buck Bunny",
  },
] as CustomVideo[];

export const images: CustomImage[] = [
  {
    src: "https://storage.googleapis.com/fdva/kellywilkinsonphoto-221008-0640.jpg",
    original:
      "https://storage.googleapis.com/fdva/kellywilkinsonphoto-221008-0640.jpg",
    width: 1368,
    height: 912,
  },
  {
    src: "https://storage.googleapis.com/fdva/kellywilkinsonphoto-221008-0650.jpg",
    original:
      "https://storage.googleapis.com/fdva/kellywilkinsonphoto-221008-0650.jpg",
    width: 1368,
    height: 912,
  },
  {
    src: "https://storage.googleapis.com/fdva/kellywilkinsonphoto-221008-7885.jpg",
    original:
      "https://storage.googleapis.com/fdva/kellywilkinsonphoto-221008-7885.jpg",
    width: 1368,
    height: 912,
  },
  {
    src: "https://storage.googleapis.com/fdva/kellywilkinsonphoto-221008-9302.jpg",
    original:
      "https://storage.googleapis.com/fdva/kellywilkinsonphoto-221008-9302.jpg",
    width: 1368,
    height: 912,
  },
  {
    src: "https://storage.googleapis.com/fdva/kellywilkinsonphoto-221008-9891.jpg",
    original:
      "https://storage.googleapis.com/fdva/kellywilkinsonphoto-221008-9891.jpg",
    width: 1368,
    height: 912,
  },
];

export const slides = images.map(
  ({ original, width, height, description }) => ({
    src: original,
    width,
    height,
    description,
  })
);

export const thumbnails = videos.map(
  ({ src, width, height, description, tags }) => ({
    src,
    original: src,
    width,
    height,
    tags: tags || [],
    description: description || "Video",
  })
);
