import { Box, Flex } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { createContext } from "react";

import BImage from "../images/rose.jpg";
import Routings from "./navigation/Routing";

export const SessionContext = createContext(null);

function Main() {
    return (
        <AnimatePresence exitBeforeEnter initial={false}>
            <Flex
                key="main"
                as={motion.div}
                initial={{ opacity: 0, y: -100 }}
                animate={{
                    opacity: 1,
                    y: 0,
                    transition: { duration: 0.5 },
                }}
                exit={{ opacity: 0 }}
                flexDir={"column"}
                overflowX={"hidden"}
                bg={"gray.800"}
            >
                <Box
                    backgroundSize="cover"
                    backgroundImage={BImage}
                    height={"100dvh"}
                >
                    <Routings />
                </Box>
            </Flex>
        </AnimatePresence>
    );
}

export default Main;
