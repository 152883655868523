import {
    Box,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import BookAClassButton from "../buttons/BookAClassButton";
import { data } from "../navigation/data";
import { NavAccordion } from "../navigation/NavAccordion";
import { NavLayout } from "../navigation/NavLayout";
import { Routes } from "../navigation/Routes";

const Header = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const location = useLocation();
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const isOnBooking = location.pathname === Routes.BOOKING;
    useEffect(() => {
        onClose();
    }, [location, onClose]);

    return (
        <Box as="nav">
            <AnimatePresence exitBeforeEnter>
                {!isOpen && !isOnBooking && (
                    <>
                        <NavLayout onClickMenu={onOpen} isMenuOpen={isOpen} />
                    </>
                )}
            </AnimatePresence>

            <Drawer
                placement="bottom"
                initialFocusRef={menuButtonRef}
                isOpen={isOpen}
                onClose={onClose}
                size="full"
            >
                <DrawerOverlay />
                <AnimatePresence exitBeforeEnter>
                    <DrawerContent
                        as={motion.div}
                        initial={{ opacity: 0, x: 1000 }}
                        animate={{
                            opacity: 0.9,
                            x: 0,
                        }}
                        exit={{ opacity: 0, x: -1000 }}
                        backgroundColor={"gray.800"}
                    >
                        <DrawerHeader p={0}>
                            <NavLayout
                                onClickMenu={onClose}
                                isMenuOpen={isOpen}
                                menuButtonRef={menuButtonRef}
                            />
                        </DrawerHeader>
                        <DrawerBody display="flex" flexDir={"column"} pb={8}>
                            <NavAccordion data={data} />

                            <Box
                                mx="auto"
                                my={{
                                    base: 4,
                                }}
                            >
                                <BookAClassButton />
                            </Box>
                        </DrawerBody>
                    </DrawerContent>
                </AnimatePresence>
            </Drawer>
        </Box>
    );
};

export default Header;
